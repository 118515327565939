import PropTypes from "prop-types"
import React from "react"
import { 
  Nav,
  Navbar, 
  NavbarBrand,
  NavItem,
  NavLink
} from 'reactstrap'
import { css } from "@emotion/core"

import Hero from './hero'

const Header = ({ siteBrand, headerImage }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    {headerImage &&
      <Hero headerImage={headerImage}/>
    }
    <Navbar color="light" light css={css`
      position: absolute;
      top: 0;
      opacity: 0.7;
      width: 100%;
    `}>
      <NavbarBrand href="/">{siteBrand}</NavbarBrand>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <NavLink href="/products">Products</NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

