import React from 'react'
import Img from "gatsby-image";
import styled from '@emotion/styled'

/*
        style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "20%"
          }}
*/
const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: ${props => props.height || '100vh'};
  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
    font-family: 'object-fit: ${props => props.fit || 'cover'} !important; object-position: ${props => props.position || '50% 50%'} !important;'
  }
`

const Hero = ({ heading, text, headerImage }) => (
  <header className="header intro">
    {headerImage && 
      <BgImg
        title="Header image"
        alt="Hero banner"
        fluid={headerImage}
        height={`20vh`}
      />
    }
  </header>
);

export default Hero

